// src/pages/TravelGuide.js
import React from 'react';
import {cards} from '../components/data'
const Card = ({ imgSrc, imgAlt, title, description}) => {
  return (
    <div className="card">
      <div className="card-side card-front">
        <img src={imgSrc} alt={imgAlt} loading="lazy" />
        <div className="card-info">
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
      <div className="card-side card-back">
        <button className="card-btn">Dublin</button>
      </div>
    </div>
  );
};

const TravelGuide = () => {
 

  return (
    <div className="body">
 
      <main>
        <section className="section blog">
          <div className="section-title">
            <h2 className="white">Dublin Travel Guide</h2>
            <div className="underline"></div>
          </div>
          <div className="section-center blog-center">
            {cards.map((card, index) => (
              <Card 
                key={index}
                imgSrc={card?.img}
                imgAlt="card_image"
                title={card.title}
                description={card.text}
              />
            ))}
          </div>
        </section>
      </main>
      
    </div>
  );
};

export default TravelGuide;
