// src/components/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';



const Sidebar = () => {
  return (
    <aside className="sidebar" id="sidebar">
      <div>
        <button className="close-btn" id="close-btn" title="Close" aria-label="Close">
          <i className="fas fa-times"></i>
        </button>
        <ul className="sidebar-links">
          <li><Link to="/">home</Link></li>
          <li><Link to="/about">about</Link></li>
          <li><Link to="/travelGuide">Dublin Travel Guide</Link></li>
          <li><Link to="/contact">contact</Link></li>
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
