import React, { useState } from 'react';

const Contact = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Your form submission logic here
    // You can display the modal after form submission
    setShowModal(true);
  };

  return (
    <div className="body">

      <section className="section single-page">
        <div className="section-title">
          <h1 className="white">contact</h1>
          <div className="underline"></div>
        </div>
        <h3 className="white" style={{ width: '75%', margin: '15px auto', textAlign: 'center' }}>If you'd like to get in touch with us...</h3>
        <form className="form-style-4" id="form-style-4" action="/submit-form" method="POST" style={{ margin: 'auto' }} onSubmit={handleSubmit}>

          <label htmlFor="field1">
            <span>Enter Your Name</span><input type="text" name="field1" required />
          </label>
          <label htmlFor="field2">
            <span>Email Address</span><input type="email" name="field2" required />
          </label>
          <label htmlFor="field3">
            <span>Short Subject</span><input type="text" name="field3" required />
          </label>

          <label>
          <span> </span><input type="submit" value="Send Letter" />
          </label>
        </form>
      </section>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <p style={{background:"green", padding:"10px 15px", width:"50%", margin:' 30px auto'}}> Your message has been submitted!</p>
          </div>
        </div>
      )}
  
    </div>
  );
};

export default Contact;

