// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/Blue and Purple Neon Gamer Logo.webp'


const Footer = () => {
  return (
    <footer className="nav" id="nav">
      <div className="nav-center" style={{ padding: '10px 0' }}>
        <div className="nav-header">
          <a href="/">
            <img src={logo} className="nav-logo" alt="nav logo" style={{ width: '100px', height: '70px' }} />
          </a>
        </div>
      </div>
      <ul className="nav-links">
          <li><Link to="/privacy">Privacy Policy</Link></li>
        
          <li><Link to="/terms">Terms and Conditions</Link></li>
        </ul>
      <div style={{ padding: '10px 20px', borderTop: '2px solid gray' }}>
      At best10deal.com, transparency and honesty are the guiding principles of our commitment to providing valuable information about physical poker rooms in Ireland. Our reviews are unbiased, based on thorough research, expert analysis, and user experiences, and focus only on basic details such as names, addresses, and phone numbers. We aim to provide unbiased content, ensuring that our platform serves as a trusted source for making informed decisions about poker room visits. Your trust is of the utmost importance, and we invite you to explore our site for comprehensive information on physical poker rooms in Ireland. If you have any questions or concerns about our practices, we are here to provide clarity and ensure a positive experience at best10deal.com. Additionally, please note that it is advised that you be over 18 years old.
      </div>
    </footer>
  );
};

export default Footer;
