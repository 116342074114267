import React from 'react';

function TermsAndConditions() {
  return (
    <div style={{margin:'30px auto', width:"80%"}}>
      <h1>Terms and Conditions of Use</h1>
      <h2>1. Acceptance of Terms</h2>
      <p>
        <strong>1.1.</strong> Welcome to www.best10deal.com . By accessing or using this Website, you agree to comply with and be bound by the following terms and conditions of use ("Terms"). If you do not agree with any of these Terms, please do not use this Website.
        <br />
        <strong>1.2.</strong> These Terms constitute a legally binding agreement between you ("User" or "You") and www.best10deal.com , governing your use of the Website.
      </p>
      <h2>2. Eligibility</h2>
      <p>
        <strong>2.1.</strong> You must be at least 18 years of age or the legal age for gambling in your jurisdiction, whichever is higher, to access this Website. By using the Website, you warrant that you meet this age requirement and are legally capable of entering into binding contracts.
        <br />
        <strong>2.2.</strong> Persons under the age of 18 are strictly prohibited from using this Website and any services offered herein.
      </p>
     
    
      <h2>3. Gambling Regulations</h2>
      <p>
        <strong>3.1.</strong> By using the Website, you acknowledge and agree to abide by all applicable laws and regulations regarding gambling activities in your jurisdiction.
        <br />
        <strong>3.2.</strong> www.best10deal.com reserves the right to refuse service, terminate accounts, or cancel wagers at its sole discretion if it believes that users are in violation of any applicable laws or these Terms.
      </p>
      <h2>44. Intellectual Property</h2>
      <p>
        <strong>4.1.</strong> All content and materials available on the Website, including but not limited to text, graphics, logos, images, audio clips, video clips, and software, are the property of www.best10deal.com or its licensors and are protected by copyright, trademark, and other intellectual property laws.
        <br />
        <strong>4.2.</strong> You may not reproduce, modify, distribute, transmit, display, perform, or otherwise use any materials or content from the Website without the prior written consent of www.best10deal.com.
      </p>
      <h2>5. Disclaimer of Warranties</h2>
      <p>
        <strong>5.1.</strong> The Website and its contents are provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied.
        <br />
        <strong>5.2.</strong> www.best10deal.com does not warrant that the Website will be uninterrupted or error-free, that defects will be corrected, or that the Website or the server that makes it available are free of viruses or other harmful components.
      </p>
      <h2>6. Limitation of Liability</h2>
      <p>
        <strong>6.1.</strong> In no event shall www.best10deal.com or its affiliates, licensors, suppliers, or service providers be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising out of or in connection with your use of the Website or its contents.
        <br />
        <strong>6.2.</strong> www.best10deal.com shall not be liable for any damages or losses resulting from any delay or failure in performance caused by events beyond its reasonable control, including but not limited to acts of God, war, terrorism, civil unrest, labor disputes, natural disasters, or internet service interruptions.
      </p>
   
      <h2>7. Changes to Terms</h2>
      <p>
        <strong>7.1.</strong> www.best10deal.com reserves the right to modify or amend these Terms at any time without prior notice. Your continued use of the Website following the posting of changes to these Terms will constitute your acceptance of such changes.
      </p>
      <h2>8. Contact Us</h2>
      <p>
        <strong>8.1.</strong> If you have any questions or concerns about these Terms, please contact us at <a href="mailto:contact@casino.com">support@best10deal.com</a>.
      </p>
    </div>
  );
}

export default TermsAndConditions;
