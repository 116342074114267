import React, { useState, useEffect } from 'react';


const Privacy = () => {
  const [date, setDate] = useState('');

  useEffect(() => {
    // Get today's date
    const today = new Date();

    // Format the date as DD-MM-YYYY
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year}`;

    // Update the date state
    setDate(formattedDate);
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  return (
    <div style={{ margin: '30px auto', width: '80%' }}>
      <div>
        <h2>Privacy Policy for Best10Deal</h2>
        <p>Date: {date}</p>
        <p>
          Best10Deal ("we" or "us") is committed to protecting the privacy and security of the personal information of individuals who visit our website. This privacy policy describes how we collect, use, and disclose personal information collected through our offline website.
        </p>
        <h3>1. Information We Collect:</h3>
        <p>
          We may collect personal information directly from you when you visit our offline casino website. This information may include:
          <ul>
            <li>Contact information such as your name, email address, and phone number.</li>
            <li>Demographic information such as your age, gender, and location.</li>
            <li>Financial information such as credit card details for processing transactions.</li>
            <li>Any other information you choose to provide to us voluntarily.</li>
          </ul>
        </p>
        <h3>2. Use of Information:</h3>
        <p>
          We may use the personal information we collect for the following purposes:
          <ul>
            <li>To provide you with information about our casino services and promotions.</li>
            <li>To process transactions and payments.</li>
            <li>To communicate with you regarding your inquiries, requests, or complaints.</li>
            <li>To personalize your experience on our website.</li>
            <li>To comply with legal and regulatory requirements.</li>
          </ul>
        </p>
        <h3>3. Disclosure of Information:</h3>
        <p>
          We may disclose your personal information to third parties in the following circumstances:
          <ul>
            <li>To our affiliates, partners, and service providers who assist us in operating our website and providing services to you.</li>
            <li>To comply with legal obligations or respond to lawful requests from government authorities.</li>
            <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
          </ul>
        </p>
        <h3>4. Data Security:</h3>
        <p>
          We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h3>5. Log Files:</h3>
        <p>
          Like many other websites, we utilize log files. These files log visitors when they visit our site. The information logged includes internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. This information is not linked to any personally identifiable information. The purpose of collecting this information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
        </p>
        <h3>6. Cookies and Web Beacons:</h3>
        <p>
          We use cookies to store information including visitors' preferences and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.
        </p>
        <h3>7. Your Choices:</h3>
        <p>
          You may choose not to provide certain personal information, but this may limit your ability to access certain features of our website or engage in transactions.
        </p>
        <h3>8. Updates to this Privacy Policy:</h3>
        <p>
          We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated policy on our website.
        </p>
        <h3>9. Contact Us:</h3>
        <p>
          If you have any questions or concerns about this privacy policy or our practices regarding your personal information, please contact us at support@best10deal.com.
        </p>
      </div>
    </div>
  );
};

export default Privacy;
