// src/pages/Home.js
import React from 'react';

import CardAbout from '../components/CardAbout';
import Items from '../components/Items';


const Home = () => {
  return (
    <div className="body">
   
      <section className="section about">
        <div className="section-center about-center">
          <article className="about-info">
            <h2>Welcome to Best10Deal</h2>
            <p>Best10deal is a meeting point for poker enthusiasts, where try your chances of winning by locating physical Irish poker rooms where you can face real players. At Best10deal, we are constantly working to provide the most up-to-date information about poker rooms in Ireland. In a safe and fair environment, you can find the best gaming table for you and enjoy an exciting poker experience. There is something for everyone from beginners to experienced players in these poker destinations. Thank you for joining the Best10Deal family. Now open your cards and start winning!</p>
          </article>
        </div>
      </section>
      <Items/>
      <CardAbout/>
    
    </div>
  );
};

export default Home;
