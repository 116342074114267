// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/Blue and Purple Neon Gamer Logo.webp'

const Navbar = () => {
  return (
    <nav className="nav" id="nav">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/">
            <img src={logo} className="nav-logo" alt="nav logo" style={{ width: '100px', height: '70px' }} />
          </Link>
          <button className="nav-btn" id="nav-btn" aria-label="Navigation Menu">
            <i className="fas fa-bars"></i>
          </button>
        </div>
        <ul className="nav-links">
          <li><Link to="/">home</Link></li>
          <li><Link to="/about">about</Link></li>
          <li><Link to="/travelGuide">Dublin Travel Guide</Link></li>
          <li><Link to="/contact">contact</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
