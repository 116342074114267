// src/pages/About.js
import React from 'react';



const About = () => {
  return (
    <div className="body">
  
      <section className="section single-page" style={{ color: 'white', textAlign: 'center' }}>
        <div className="section-title">
          <h1>About us</h1>
          <div className="underline"></div>
        </div>
        <div className="section-center page-info">
        <p>Your esteemed gateway to discovering the most esteemed poker rooms nestled within the scenic landscapes of Ireland. We understand that a truly remarkable excursion extends far beyond the realm of gaming; it embodies a symphony of unforgettable experiences, lavish accommodations, and unparalleled service. At Best10Deal, we stand as your dedicated ally in unveiling the epitome of poker excellence, ensuring that your journey transcends the ordinary and ventures into the realm of the extraordinary.</p>
      
      <h3>Our Mission</h3>
      <p>At the heart of Best10Deal lies a steadfast commitment: to furnish you with an exhaustive repository of information and impartial evaluations encompassing Ireland's premier poker establishments. Our mission is clear—to empower you with the knowledge and insights necessary to make well-informed decisions, thus elevating your poker escapades to unprecedented heights.</p>
      
      <h3>What Distinguishes Us?</h3>
      <p><strong>Unbiased Reviews:</strong> Integrity is the bedrock upon which our platform is built. We take immense pride in delivering reviews that are not only comprehensive but also imbued with transparency and objectivity. Our meticulous assessments ensure that you navigate the diverse landscape of poker venues with unwavering confidence.</p>
      
      <p><strong>Advisory Note:</strong> While we celebrate the allure of poker, we also champion responsible gaming practices. We underscore the importance of discretion and prudence, particularly for individuals aged 18 and above, fostering an environment where gaming is enjoyed responsibly and ethically.</p>
      
      <h3>How We Can Enrich Your Experience?</h3>
      <p>Best10Deal serves as your quintessential ally in unraveling the hidden gems of Ireland's poker scene. Our user-centric website serves as a veritable treasure trove, streamlining the process of comparison and selection to cater to your unique preferences and priorities. Immerse yourself in our meticulously curated listings, delve into our in-depth analyses, and leverage our ratings to craft an odyssey that surpasses all expectations. Whether you seek the thrill of high-stakes tournaments or the allure of intimate, exclusive settings, Best10Deal is your passport to an unforgettable journey through Ireland's vibrant poker landscape. Embark on your quest to unearth the finest poker rooms today with Best10Deal, and allow us to be your steadfast companion in crafting memories that will last a lifetime.</p>
        </div>
      </section>
 
    </div>
  );
};

export default About;
