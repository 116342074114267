import logo01 from "../images/logo00.webp";
import logo02 from "../images/Screenshot 2024-03-27 214651.webp";
import logo03 from "../images/logotrump.webp";
import logo04 from "../images/logo7.4f4c355fc145c70736fd.webp";
import logo05 from "../images/room1.webp";
import logo06 from "../images/Screenshot 2024-03-18 135015.webp";
import logo07 from "../images/logo4.webp";
import logo08 from "../images/logo1.webp";
import logo09 from "../images/WebsiteLogosilver.webp";
import logo10 from "../images/the4aces.webp";
import { FaStar } from "react-icons/fa";
import card01 from "../images/travel001.webp";
import card02 from "../images/travel02.webp";
import card03 from "../images/travel03.webp";
import card04 from "../images/travel04.webp";
import card05 from "../images/travel05.webp";
import card06 from "../images/travel006.webp";

const data = [
  {
    id: 1,
    logo: logo01,
    alt: "carltoncasinoclub",
    score: "9.9",
    email: "bookings@carltoncasinoclub.com",
    phone: "01 515 4704",
    adress: "F55/56 O'Connell Street Dublin 1, Dublin, Ireland",
    link: "https://carltoncasinoclub.gravity.ie/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 2,
    logo: logo02,
    alt: "premiercasinos",
    score: "9.7",
    email: "nfo@premiercasinos.ie",
    phone: "01 403 0911",
    adress: "Playtime, Unit 3a, Orchard Road, Clondalkin, Dublin 22, D22 NT66",
    link: "https://premiercasinos.ie/casino-locations/clondalkin-dublin-casino/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 3,
    logo: logo03,
    alt: "trumpsplaza",
    score: "9.6",
    email: "info@trumpsplaza.com",
    phone: "01 840 5565    ",
    adress: "10-11 FORSTER WAY, SWORDS, DUBLIN, K67NY61, IRELAND",
    link: "https://www.trumpsplaza.com/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 4,
    logo: logo04,
    alt: "adelaidecasino",
    score: "9.3",
    email: "contact@adelaidecasino.ie",
    phone: "071 915 0515",
    adress:
      "Ted Nealon Rd, Adelaide Street, Abbeyquarter North, Sligo, Ireland",
    link: "http://www.adelaidecasino.ie/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 5,
    logo: logo05,
    alt: "gamcare",
    score: "9.1",
    email: "info@gamcare.org.uk",
    phone: "071 915 0515",
    adress: "63 Lower Dorset Street Dublin 1,Dublin,Ireland",
    link: "https://d1casino.ie/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 6,
    logo: logo06,
    alt: "thesportingemporium",
    score: "9.1",
    email: "info@thesportingemporium.com",
    phone: "01 703 0600",
    adress: "South Anne Street, Dublin 2, Dublin, Ireland",
    link: "https://www.thesportingemporium.com/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 7,
    logo: logo07,
    alt: "morris21casinos",
    score: "8.9",
    email: "info@morris21casinos.com",
    phone: "01 872 7342",
    adress: "153 Parnell street, Rotunda, Dublin 1, Dublin, Ireland",
    link: "https://www.morris21casinos.com/?utm_source=HeyDublin",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 8,
    logo: logo08,
    score: "8.9",
    email: "j.p.poker@hotmail.com",
    alt: "j.p.poker",
    phone: " 87 990 4586",
    adress: "The Arena Centre Whitetown Way Tallaght Dublin 24",
    link: "https://jppoker.ie/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 9,
    logo: logo09,
    score: "8.5",
    alt: "theeglinton",
    email: "theeglinton10@gmail.com",
    phone: "01 915 69632",
    adress: "Eglinton Street, Galway, Ireland",
    link: "https://theeglinton.com/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
  {
    id: 10,
    logo: logo10,
    score: "8.4",
    alt: "4acescasino",
    email: "4acescasino2020@gmail.com",
    phone: " 91 589199",
    adress: "19 Dominick Street Upper Galway, Ireland",
    link: "https://www.the4aces.ie/",
    stars: [
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
      <FaStar className="text-yellow-500 mr-1 text-xl" />,
    ],
  },
];

const cards = [
  {
    id: 1,
    img: card01,

    text: `Walk/Bike around St. Stephen’s Green or Phoenix Park.St. Stephen’s Green Park is conveniently located in
the heart of Dublin City Centre. If you want to escape the busy Grafton Street, walk through the
historic gates of the park and take a stroll through its bright flower beds and fountains.`,
    title: "1. St. Stephen’s Green",
  },
  {
    id: 2,
    img: card02,
    text: `
    There are SO MANY great pubs, obviously they needed their own section! While Temple Bar is the most
    well-known area for nightlife (not to mention, is very aesthetically charming) some will tell you to
    avoid it because it’s considered very touristy.`,
    title: "2.Dublin restaurants + pubs",
  },
  {
    id: 3,
    img: card03,
    text: `
    Our favorite meal of the entire trip. DO NOT MISS SPITALFIELDS if you can help it. Everything was
    absolutely fantastic. Try to snag one of the two booths in the bar so you can watch them cooking through
    the open kitchen. 
`,
    title: "3.Eating and Drinking–Not pubs",
  },
  {
    id: 4,
    img: card04,
    text: `
    They have locations all over Ireland (in Killarney as well!) and some of the best ice cream I’ve had in
    a long time–I LOVED their Dingle Sea Salt flavor, but their other famous flavor is the Irish Brown Bread
    ice cream. (I preferred the sea salt though!)
  .`,
    title: "4.Murphy’s Ice Cream:",
  },
  {
    id: 5,
    img: card05,
    text: `
    Aran Sweater Market:
    Where to stocked up on Irish sweaters for us and June! They’ll ship back to the states for you for free
    with purchase of $100 or more, so you don’t have to leave room in your suitcase! (We meant to go to
    Quill’s in Killarney but didn’t make it, so went here instead!)
  `,
    title: "5.Shopping in Dublin:",
  },
  {
    id: 1,
    img: card06,
    text: `
    For a walk that’s outside the busy Dublin City Centre, you can check out the popular Howth Cliff Walk.
    Howth’s cliffs rise up to 171 metres above the Irish Sea and give you the perfect view of the east coast
    of Ireland. It makes for a perfect day trip, especially when you catch a sunny day.
  `,
    title: "6.Howth Cliff Walk",
  },
];

export { data, cards };
