import React from 'react';
import { data } from '../components/data';

export default function Items() {
  return (
    <div style={{ width: "80%", margin: 'auto' }}>
      {data?.map((item, idx) => (
        <article className="service" style={{ boxShadow: '10px -17px 21px 5px rgb(1, 46, 7)' }} key={idx}>
          <div className="article-logo">
            <img src={item?.logo} alt={item?.alt} loading="lazy" />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <h4 className="service-score">{item?.score}</h4>
            <div style={{display: 'flex', gap:'10px', color:'yellow', fontSize: '22px'}}>
            {item?.stars}
            </div>
           
          </div>
          <ul>
            <li>E-mail: {item?.email}</li>
            <li>Phone: {item?.phone}</li>
            <li>Address: {item?.adress}</li>
          </ul>
          <div className="button-area">
            <a href={item?.link} className="btn btn-green" target="_blank" rel="noopener noreferrer" style={{fontWeight:"800"}}>
              Visit
            </a>
          </div>
        </article>
      ))}
    </div>
  );
}
