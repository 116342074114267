// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import Terms from "./Pages/Terms";
import TravelGuide from "./Pages/TravelGuide";
import "./css/style.css"; // Ensure the path is correct
import CookieConsent from "react-cookie-consent";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

function loadGTM() {
  const script = document.createElement("script");
  script.src = "https://www.googletagmanager.com/gtm.js?id=GTM-WT55PC3W";
  script.async = true;
  document.head.appendChild(script);
}

function App() {
  useEffect(() => {
    loadGTM();
  }, []);
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="mySiteCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "consent_given",
          });
        }}
      >
        This site uses cookies. Please accept cookies for a better user
        experience.
      </CookieConsent>
      <Router>
      <Navbar/>
      <Sidebar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />

          <Route path="/travelGuide" element={<TravelGuide />} />
        </Routes>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
