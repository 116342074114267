import React from 'react'

export default function CardAbout() {
  return (
    <section className="section about">
    <div className="section-center about-center">
      <article className="about-info">
        <h2>Your Guide To Premier Poker Rooms In Ireland</h2>
        <p>Are you a poker enthusiast looking for the ultimate gaming experience in Ireland? Look no further! Best10Deal.com is here to guide you through the premier poker rooms . Whether you're a seasoned player or just starting your journey in the world of poker, Ireland offers a vibrant and diverse poker scene that caters to all levels of expertise. Plan Your Poker Adventure Today! Ready to embark on an unforgettable poker adventure in Ireland? Let Best10Deal.com be your trusted companion as you explore the premier poker rooms across the country. Whether you're seeking thrilling live tournaments or exciting online gameplay, we've got you covered. Join us today and discover why Ireland is a haven for poker enthusiasts worldwide. Best10Deal.com - Your Ultimate Destination for Poker Excellence in Ireland.</p>
      </article>
    </div>
  </section>
  )
}
